import * as React from "react"
import { FaXmark } from "react-icons/fa6"

type BannerProps = {
  children?: React.ReactNode
  appendClassNames?: string
  sticky?: boolean
  fixed?: boolean
  opened?: boolean
  closeable?: boolean
  onClose?: () => void
}

export function Banner(props: BannerProps): React.JSX.Element {
  const {
    appendClassNames = "",
    sticky = false,
    fixed = false,
    opened = false,
    closeable = false,
  } = props

  const handleClose = () => {
    props.onClose && props.onClose()
  }

  return (
    <>
      {
        opened &&
        <div className={
          "w-full h-fit flex flex-row justify-between items-center gap-3 md:!gap-2 p-3.5 " +
          (sticky ? "sticky top-0 z-10 " : " ") +
          (fixed ? "fixed top-0 z-10 " : " ") +
          appendClassNames
        }>
          <div>
            {props.children}
          </div>

          {
            closeable &&
            <button onClick={handleClose} aria-label="Close">
              <FaXmark className="w-5 h-5 text-white" aria-hidden="true" />
            </button>
          }
        </div>
      }
    </>
  )
}
